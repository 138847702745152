.MuiTypography-h4,
.MuiTypography-h5 {
	font-family: Tajawal, sans-serif !important;
}

.link-popup {
	left: auto;
	right: 5px;
}

.rdw-emoji-modal {
	right: auto;
	left: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

#universe-container {
	flex-grow: 1;
	display: flex;
	position: relative;
}

#universe {
	z-index: 1;
	position: absolute;
	/* overflow: hidden; */
	width: 100%;
	height: 100%;
}

#galaxy {
	position: relative;
	width: 100%;
	height: 100%;
	transform: rotateX(75deg);
	transform-style: preserve-3d;
}

#sun,
.planet {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 1em;
	height: 1em;
	margin-top: -0.5em;
	margin-left: -0.5em;
	/*   border-radius: 50%; */
	transform-style: preserve-3d;
}

#sun {
	/*   background-color: #FB7209; */
	background-repeat: no-repeat;
	background-size: cover;
	/*   box-shadow: 0 0 60px rgba(255, 160, 60, 0.4);  */
	transform: rotateX(-75deg);
	width: 50%;
}

.pos {
	position: absolute;
	transform-style: preserve-3d;
	animation-name: invert;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.planet {
	/*   background-color: #202020; */
	background-repeat: no-repeat;
	background-size: cover;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.orbita {
	position: absolute;
	top: 50%;
	left: 50%;
	border: 4px solid #f27021;
	border-radius: 50%;
	animation-name: orbit1;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	transform-style: preserve-3d;
}

.orbitb {
	position: absolute;
	top: 50%;
	left: 50%;
	border: 4px solid #f27021;
	border-radius: 50%;
	animation-name: orbit2;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	transform-style: preserve-3d;
}

.orbitc {
	position: absolute;
	top: 50%;
	left: 50%;
	border: 4px solid #f27021;
	border-radius: 50%;
	animation-name: orbit3;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	transform-style: preserve-3d;
}

/* Animations */
@keyframes orbit1 {
	0% {
		transform: rotateY(45deg) rotateZ(0deg);
	}

	100% {
		transform: rotateY(45deg) rotateZ(-360deg);
	}
}

@keyframes orbit2 {
	0% {
		transform: rotateZ(0deg);
	}

	100% {
		transform: rotateZ(-360deg);
	}
}

@keyframes orbit3 {
	0% {
		transform: rotateY(-45deg) rotateZ(0deg);
	}

	100% {
		transform: rotateY(-45deg) rotateZ(-360deg);
	}
}

@keyframes invert {
	0% {
		transform: rotateX(-90deg) rotateY(360deg) rotateZ(0deg);
	}

	100% {
		transform: rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
	}
}

/* Orbit sizes */
#mercury.orbita {
	width: 100%;
	height: 100%;
	margin-top: -50%;
	margin-left: -50%;
}

#earth.orbitb {
	width: 100%;
	height: 100%;
	margin-top: -50%;
	margin-left: -50%;
}

#pluto.orbitc {
	width: 100%;
	height: 100%;
	margin-top: -50%;
	margin-left: -50%;
}

/* Planet starting positions */
#mercury .pos,
#earth .pos,
#pluto .pos {
	left: 50%;
	top: -1px;
	/*   border: solid 3px red; */
}

/* Planet animation durations */
#mercury.orbita,
#mercury .pos {
	animation-duration: 3s;
}

#earth.orbitb,
#earth .pos {
	animation-duration: 3.5s;
}

#pluto.orbitc,
#pluto .pos {
	animation-duration: 4.5s;
}

/* Planet sizes */
#sun {
	font-size: 18em;
}

#mercury,
#earth,
#pluto {
	font-size: 4em;
}

/* images */

#sun {
	background-image: url(https://firebasestorage.googleapis.com/v0/b/mujaz-42d66.appspot.com/o/phone-mujaz-2.png?alt=media&token=5c0d3d11-d6c7-4eec-a360-da6ef0bc0c64);
}

.planet {
	background-image: url(https://firebasestorage.googleapis.com/v0/b/mujaz-42d66.appspot.com/o/Moujaz-emblum.png?alt=media&token=73295b87-dc0c-4b9f-950f-4c24bab5c4ba);
}

.custom-shape-divider-bottom-1640712031 {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	overflow: hidden;
	line-height: 0;
	transform: rotate(180deg);
}

.custom-shape-divider-bottom-1640712031 svg {
	position: relative;
	display: block;
	width: calc(180% + 1.3px);
	height: 261px;
	transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1640712031 .shape-fill {
	fill: #efefef;
}

.confirm-box {
	z-index: 1000;
	position: absolute;
	left: 35% !important;
	top: 45%;
}

.editorClassName {
	background: #ffffff;
	min-height: 255px;
	margin-top: 20px;
	border-radius: 25px;
	border: 1px solid #e0e0e0;
	padding: 5px;
}

.rdw-editor-toolbar {
	visibility: visible;
	border-radius: 25px;
	padding: 12px;
}

.confirm-box {
	position: fixed !important;
	top: 25% !important;
}

.tss-1oww3lq-confirmContainer {
	padding: 70px 60px !important;
	border-radius: 25px !important;
}

[data-rbd-drag-handle-context-id="0"] {
	margin-bottom: 20px !important;
}

.dragactive {
	background-color: rgb(243 243 243);
	border-radius: 20px;
}

.dragcomplete {
	background-color: rgb(243 243 243);
	border-radius: 20px;
}

.remove {
	background-color: rgb(243 243 243);
	border-radius: 20px;
}

.MuiOutlinedInput-root {
	background: #FFF;
}

.muirtl-9ttmzl-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
	color: black !important;
}

.MuiPickerStaticWrapper-content {
	direction: rtl !important;
}

.muirtl-1wf8b0h-MuiTabs-flexContainer {
	display: flex;
	justify-content: space-around !important;

}

.muirtl-1gsodu5-MuiButtonBase-root-MuiTab-root {
	font-size: 1.3rem !important;
	font-weight: bold !important;
}

.react-datepicker-wrapper input {
	border-radius: 15px;
	height: 35px;
	text-align: center;
	font-weight: bold;
	/* border: none !important; */
	border: 1px solid #bdbdbd;
}

.tss-lgeaaj-richTextWraper {
	word-break: break-all;
}

.rtl-mujaz h1,
.rtl-mujaz p {
	text-align: right;
}

.DraftEditor-editorContainer {
	word-break: break-word;
}

.img-fluid {
	max-width: 40% !important;
}
.img-fluid-50 {
	max-width: 50% !important;
}
.img-fluid-80 {
	max-width: 80% !important;
}
.img-fluid-100 {
	max-width: 100% !important;
}

.contact-section-mujaz {
	background-color: #ff7764 !important;
}

.white-text {
	color: white !important;
}

.orange-mujaz-text {
	color: #ff7764 !important;
}

span.up-to {
	font-size: 14px;
}

.text-line:before {
	background-color: #fff !important;
}

.orange-block-500-540 {
	max-width: 500px;
	max-height: 540px;
	background-color: #ff7764 !important;
}

button.MuiButtonBase-root {
	box-shadow: none;
	border-radius: 10px;
}

input[type="range"] {
	-webkit-appearance: none;
	margin-right: 35px;
	margin-top: 35px;
	width: 200px;
	height: 7px;
	background: rgba(255, 255, 255, 0.6);
	border-radius: 5px;
	background: #ff7764;
	background-size: 70% 100%;
	background-repeat: no-repeat;
  }
  
  input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background: #ff7764;
	cursor: ew-resize;
	box-shadow: 0 0 2px 0 #555;
	transition: background .3s ease-in-out;
  }
  
  input[type=range]::-webkit-slider-runnable-track  {
	-webkit-appearance: none;
	box-shadow: none;
	border: none;
	background: transparent;
  }
.wizard-li-spacing{
	background: #eff0f7;
    padding: 0 20px;
}
.react-form-wizard .clearfix:after{
	display: none !important;
	content: none !important;
}
.react-form-wizard .wizard-card-footer{
	display: flex !important;
	justify-content: space-between !important;
}
.react-form-wizard.sm .wizard-icon-circle{
	width: 45px !important;
	height: 45px !important;
}
.react-form-wizard.sm .wizard-navigation .wizard-progress-with-circle{
	height: 3px !important;
	top: 45px !important;
}
.react-form-wizard, .wizard-navigation{
	display: flex;
	flex-direction: column;
	gap: 15px;
}
.react-form-wizard .wizard-tab-content {
    min-height: 100px !important;
    padding: 30px 0px 0px !important;
    text-align: center !important;
}
ul.muirtl-6hp17o-MuiList-root-MuiMenu-list{
display: flex;
flex-direction: column;
}
.stepTitle{
	font-size: 16px;
	color: #072448;
}
  #root{
	background-color: #EFF0F7;
	/* background-color: #fff9ef; */
	/* background-color: #fff; */
  }
  .apexcharts-legend-marker{
	margin-left: 3px;
  }
  .breadcrumb-item+.breadcrumb-item::before{
	rotate: 180deg;
  }
 .ps-sidebar-root{
	border: none !important;
 }

 .react-datepicker-wrapper input#sponsor-from, .react-datepicker-wrapper input#sponsor-to{
	border: 1px solid #c4c4c4 !important;
    border-radius: 10px !important;
    height: 45px
 }

 ::-webkit-scrollbar {
	width: 10px;
	height: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: #FFCB00; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background: #C19500; 
  }
  .rdp{
	--rdp-accent-color: #ff7764;
	--rdp-background-color: #f9b68e;
  }
  .rdp-months {
    display: flex;
    justify-content: space-around;
}

.bold{
	font-weight: bold !important;
}

.muirtl-ebyfgi-MuiButtonBase-root-MuiCheckbox-root{
	padding: 0px !important;
}
.muirtl-1dcyl7-MuiTypography-root{
	font-size: 0.8rem !important;
}

.errorSnakbar{
	background-color: red !important;
}


/* rich text lexical*/

  
  .other h2 {
	font-size: 18px;
	color: #444;
	margin-bottom: 7px;
  }
  
  .other a {
	color: #777;
	text-decoration: underline;
	font-size: 14px;
  }
  
  .other ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
  }
  
  .App {
	font-family: sans-serif;
	text-align: center;
  }
  
  h1 {
	font-size: 24px;
	color: #333;
  }
  
  .ltr {
	text-align: left;
  }
  
  .rtl {
	text-align: right;
  }
  
  .editor-container {
	margin: 20px auto 20px auto;
	border-radius: 2px;
	max-width: 600px;
	color: #000;
	position: relative;
	line-height: 20px;
	font-weight: 400;
	text-align: left;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
  }
  
  .editor-inner {
	background: #fff;
	position: relative;
  }
  
  .editor-input {
	min-height: 150px;
	resize: none;
	font-size: 15px;
	caret-color: rgb(5, 5, 5);
	position: relative;
	tab-size: 1;
	outline: 0;
	padding: 15px 10px;
	caret-color: #444;
  }
  
  .editor-placeholder {
	color: #999;
	overflow: hidden;
	position: absolute;
	text-overflow: ellipsis;
	top: 15px;
	left: 10px;
	font-size: 15px;
	user-select: none;
	display: inline-block;
	pointer-events: none;
  }
  
  .editor-text-bold {
	font-weight: bold;
  }
  
  .editor-text-italic {
	font-style: italic;
  }
  
  .editor-text-underline {
	text-decoration: underline;
  }
  
  .editor-text-strikethrough {
	text-decoration: line-through;
  }
  
  .editor-text-underlineStrikethrough {
	text-decoration: underline line-through;
  }
  
  .editor-text-code {
	background-color: rgb(240, 242, 245);
	padding: 1px 0.25rem;
	font-family: Menlo, Consolas, Monaco, monospace;
	font-size: 94%;
  }
  
  .editor-link {
	color: rgb(33, 111, 219);
	text-decoration: none;
  }
  
  .tree-view-output {
	display: block;
	background: #222;
	color: #fff;
	padding: 5px;
	font-size: 12px;
	white-space: pre-wrap;
	margin: 1px auto 10px auto;
	max-height: 250px;
	position: relative;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	overflow: auto;
	line-height: 14px;
  }
  
  .editor-code {
	background-color: rgb(240, 242, 245);
	font-family: Menlo, Consolas, Monaco, monospace;
	display: block;
	padding: 8px 8px 8px 52px;
	line-height: 1.53;
	font-size: 13px;
	margin: 0;
	margin-top: 8px;
	margin-bottom: 8px;
	tab-size: 2;
	/* white-space: pre; */
	overflow-x: auto;
	position: relative;
  }
  
  .editor-code:before {
	/* content: attr(data-gutter); */
	position: absolute;
	background-color: #eee;
	left: 0;
	top: 0;
	border-right: 1px solid #ccc;
	padding: 8px;
	color: #777;
	white-space: pre-wrap;
	text-align: right;
	min-width: 25px;
  }
  .editor-code:after {
	/* content: attr(data-highlight-language); */
	top: 0;
	right: 3px;
	padding: 3px;
	font-size: 10px;
	text-transform: uppercase;
	position: absolute;
	color: rgba(0, 0, 0, 0.5);
  }
  
  .editor-tokenComment {
	color: slategray;
  }
  
  .editor-tokenPunctuation {
	color: #999;
  }
  
  .editor-tokenProperty {
	color: #905;
  }
  
  .editor-tokenSelector {
	color: #690;
  }
  
  .editor-tokenOperator {
	color: #9a6e3a;
  }
  
  .editor-tokenAttr {
	color: #07a;
  }
  
  .editor-tokenVariable {
	color: #e90;
  }
  
  .editor-tokenFunction {
	color: #dd4a68;
  }
  
  .editor-paragraph {
	margin: 0;
	margin-bottom: 8px;
	position: relative;
  }
  
  .editor-paragraph:last-child {
	margin-bottom: 0;
  }
  
  .editor-heading-h1 {
	font-size: 24px;
	color: rgb(5, 5, 5);
	font-weight: 400;
	margin: 0;
	margin-bottom: 12px;
	padding: 0;
  }
  
  .editor-heading-h2 {
	font-size: 15px;
	color: rgb(101, 103, 107);
	font-weight: 700;
	margin: 0;
	margin-top: 10px;
	padding: 0;
	text-transform: uppercase;
  }
  
  .editor-quote {
	margin: 0;
	margin-left: 20px;
	font-size: 15px;
	color: rgb(101, 103, 107);
	border-left-color: rgb(206, 208, 212);
	border-left-width: 4px;
	border-left-style: solid;
	padding-left: 16px;
  }
  
  .editor-list-ol {
	padding: 0;
	margin: 0;
	margin-left: 16px;
  }
  
  .editor-list-ul {
	padding: 0;
	margin: 0;
	margin-left: 16px;
  }
  
  .editor-listitem {
	margin: 8px 32px 8px 32px;
  }
  
  .editor-nested-listitem {
	list-style-type: none;
  }
  
  pre::-webkit-scrollbar {
	background: transparent;
	width: 10px;
  }
  
  pre::-webkit-scrollbar-thumb {
	background: #999;
  }
  
  .debug-timetravel-panel {
	overflow: hidden;
	padding: 0 0 10px 0;
	margin: auto;
	display: flex;
  }
  
  .debug-timetravel-panel-slider {
	padding: 0;
	flex: 8;
  }
  
  .debug-timetravel-panel-button {
	padding: 0;
	border: 0;
	background: none;
	flex: 1;
	color: #fff;
	font-size: 12px;
  }
  
  .debug-timetravel-panel-button:hover {
	text-decoration: underline;
  }
  
  .debug-timetravel-button {
	border: 0;
	padding: 0;
	font-size: 12px;
	top: 10px;
	right: 15px;
	position: absolute;
	background: none;
	color: #fff;
  }
  
  .debug-timetravel-button:hover {
	text-decoration: underline;
  }
  
  .emoji {
	color: transparent;
	background-size: 16px 16px;
	background-position: center;
	background-repeat: no-repeat;
	vertical-align: middle;
	margin: 0 -1px;
  }
  
  .emoji-inner {
	padding: 0 0.15em;
  }
  
  .emoji-inner::selection {
	color: transparent;
	background-color: rgba(150, 150, 150, 0.4);
  }
  
  .emoji-inner::moz-selection {
	color: transparent;
	background-color: rgba(150, 150, 150, 0.4);
  }
  
  .emoji.happysmile {
	background-image: url(./atoms/lexical/images/emoji/1F642.png);
  }
  
  .toolbar {
	display: flex;
	margin-bottom: 1px;
	background: #fff;
	padding: 4px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	vertical-align: middle;
  }
  
  .toolbar button.toolbar-item {
	border: 0;
	display: flex;
	background: none;
	border-radius: 10px;
	padding: 8px;
	cursor: pointer;
	vertical-align: middle;
  }
  
  .toolbar button.toolbar-item:disabled {
	cursor: not-allowed;
  }
  
  .toolbar button.toolbar-item.spaced {
	margin-right: 2px;
  }
  
  .toolbar button.toolbar-item i.format {
	background-size: contain;
	display: inline-block;
	height: 18px;
	width: 18px;
	margin-top: 2px;
	vertical-align: -0.25em;
	display: flex;
	opacity: 0.6;
  }
  
  .toolbar button.toolbar-item:disabled i.format {
	opacity: 0.2;
  }
  
  .toolbar button.toolbar-item.active {
	background-color: rgba(223, 232, 250, 0.3);
  }
  
  .toolbar button.toolbar-item.active i {
	opacity: 1;
  }
  
  .toolbar .toolbar-item:hover:not([disabled]) {
	background-color: #eee;
  }
  
  .toolbar .divider {
	width: 1px;
	background-color: #eee;
	margin: 0 4px;
  }
  
  .toolbar select.toolbar-item {
	border: 0;
	display: flex;
	background: none;
	border-radius: 10px;
	padding: 8px;
	vertical-align: middle;
	-webkit-appearance: none;
	-moz-appearance: none;
	width: 70px;
	font-size: 14px;
	color: #777;
	text-overflow: ellipsis;
  }
  
  .toolbar select.code-language {
	text-transform: capitalize;
	width: 130px;
  }
  
  .toolbar .toolbar-item .text {
	display: flex;
	line-height: 20px;
	width: 200px;
	vertical-align: middle;
	font-size: 14px;
	color: #777;
	text-overflow: ellipsis;
	width: 70px;
	overflow: hidden;
	height: 20px;
	text-align: left;
  }
  
  .toolbar .toolbar-item .icon {
	display: flex;
	width: 20px;
	height: 20px;
	user-select: none;
	margin-right: 8px;
	line-height: 16px;
	background-size: contain;
  }
  
  .toolbar i.chevron-down {
	margin-top: 3px;
	width: 16px;
	height: 16px;
	display: flex;
	user-select: none;
  }
  
  .toolbar i.chevron-down.inside {
	width: 16px;
	height: 16px;
	display: flex;
	margin-left: -25px;
	margin-top: 11px;
	margin-right: 10px;
	pointer-events: none;
  }
  
  i.chevron-down {
	background-color: transparent;
	background-size: contain;
	display: inline-block;
	height: 8px;
	width: 8px;
	background-image: url(./atoms/lexical/images/icons/chevron-down.svg);
  }
  
  #block-controls button:hover {
	background-color: #efefef;
  }
  
  #block-controls button:focus-visible {
	border-color: blue;
  }
  
  #block-controls span.block-type {
	background-size: contain;
	display: block;
	width: 18px;
	height: 18px;
	margin: 2px;
  }
  
  #block-controls span.block-type.paragraph {
	background-image: url(./atoms/lexical/images/icons/text-paragraph.svg);
  }
  
  #block-controls span.block-type.h1 {
	background-image: url(./atoms/lexical/images/icons/type-h1.svg);
  }
  
  #block-controls span.block-type.h2 {
	background-image: url(./atoms/lexical/images/icons/type-h2.svg);
  }
  
  #block-controls span.block-type.quote {
	background-image: url(./atoms/lexical/images/icons/chat-square-quote.svg);
  }
  
  #block-controls span.block-type.ul {
	background-image: url(./atoms/lexical/images/icons/list-ul.svg);
  }
  
  #block-controls span.block-type.ol {
	background-image: url(./atoms/lexical/images/icons/list-ol.svg);
  }
  
  #block-controls span.block-type.code {
	background-image: url(./atoms/lexical/images/icons/code.svg);
  }
  
  .dropdown {
	z-index: 5;
	display: block;
	position: absolute;
	box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
	  inset 0 0 0 1px rgba(255, 255, 255, 0.5);
	border-radius: 8px;
	min-width: 100px;
	min-height: 40px;
	background-color: #fff;
  }
  
  .dropdown .item {
	margin: 0 8px 0 8px;
	padding: 8px;
	color: #050505;
	cursor: pointer;
	line-height: 16px;
	font-size: 15px;
	display: flex;
	align-content: center;
	flex-direction: row;
	flex-shrink: 0;
	justify-content: space-between;
	background-color: #fff;
	border-radius: 8px;
	border: 0;
	min-width: 268px;
  }
  
  .dropdown .item .active {
	display: flex;
	width: 20px;
	height: 20px;
	background-size: contain;
  }
  
  .dropdown .item:first-child {
	margin-top: 8px;
  }
  
  .dropdown .item:last-child {
	margin-bottom: 8px;
  }
  
  .dropdown .item:hover {
	background-color: #eee;
  }
  
  .dropdown .item .text {
	display: flex;
	line-height: 20px;
	flex-grow: 1;
	width: 200px;
  }
  
  .dropdown .item .icon {
	display: flex;
	width: 20px;
	height: 20px;
	user-select: none;
	margin-right: 12px;
	line-height: 16px;
	background-size: contain;
  }
  
  .link-editor {
	position: absolute;
	z-index: 100;
	top: -10000px;
	left: -10000px;
	margin-top: -6px;
	max-width: 300px;
	width: 100%;
	opacity: 0;
	background-color: #fff;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
	transition: opacity 0.5s;
  }
  
  .link-editor .link-input {
	display: block;
	width: calc(100% - 24px);
	box-sizing: border-box;
	margin: 8px 12px;
	padding: 8px 12px;
	border-radius: 15px;
	background-color: #eee;
	font-size: 15px;
	color: rgb(5, 5, 5);
	border: 0;
	outline: 0;
	position: relative;
	font-family: inherit;
  }
  
  .link-editor div.link-edit {
	background-image: url(./atoms/lexical/images/icons/pencil-fill.svg);
	background-size: 16px;
	background-position: center;
	background-repeat: no-repeat;
	width: 35px;
	vertical-align: -0.25em;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	cursor: pointer;
  }
  
  .link-editor .link-input a {
	color: rgb(33, 111, 219);
	text-decoration: none;
	display: block;
	white-space: nowrap;
	overflow: hidden;
	margin-right: 30px;
	text-overflow: ellipsis;
  }
  
  .link-editor .link-input a:hover {
	text-decoration: underline;
  }
  
  .link-editor .button {
	width: 20px;
	height: 20px;
	display: inline-block;
	padding: 6px;
	border-radius: 8px;
	cursor: pointer;
	margin: 0 2px;
  }
  
  .link-editor .button.hovered {
	width: 20px;
	height: 20px;
	display: inline-block;
	background-color: #eee;
  }
  
  .link-editor .button i,
  .actions i {
	background-size: contain;
	display: inline-block;
	height: 20px;
	width: 20px;
	vertical-align: -0.25em;
  }
  
  i.undo {
	background-image: url(./atoms/lexical/images/icons/arrow-counterclockwise.svg);
  }
  
  i.redo {
	background-image: url(./atoms/lexical/images/icons/arrow-clockwise.svg);
  }
  
  .icon.paragraph {
	background-image: url(./atoms/lexical/images/icons/text-paragraph.svg);
  }
  
  .icon.large-heading,
  .icon.h1 {
	background-image: url(./atoms/lexical/images/icons/type-h1.svg);
  }
  
  .icon.small-heading,
  .icon.h2 {
	background-image: url(./atoms/lexical/images/icons/type-h2.svg);
  }
  
  .icon.bullet-list,
  .icon.ul {
	background-image: url(./atoms/lexical/images/icons/list-ul.svg);
  }
  
  .icon.numbered-list,
  .icon.ol {
	background-image: url(./atoms/lexical/images/icons/list-ol.svg);
  }
  
  .icon.quote {
	background-image: url(./atoms/lexical/images/icons/chat-square-quote.svg);
  }
  
  .icon.code {
	background-image: url(./atoms/lexical/images/icons/code.svg);
  }
  
  i.bold {
	background-image: url(./atoms/lexical/images/icons/type-bold.svg);
  }
  
  i.italic {
	background-image: url(./atoms/lexical/images/icons/type-italic.svg);
  }
  
  i.underline {
	background-image: url(./atoms/lexical/images/icons/type-underline.svg);
  }
  
  i.strikethrough {
	background-image: url(./atoms/lexical/images/icons/type-strikethrough.svg);
  }
  
  i.code {
	background-image: url(./atoms/lexical/images/icons/code.svg);
  }
  
  i.link {
	background-image: url(./atoms/lexical/images/icons/link.svg);
  }
  
  i.left-align {
	background-image: url(./atoms/lexical/images/icons/text-left.svg);
  }
  
  i.center-align {
	background-image: url(./atoms/lexical/images/icons/text-center.svg);
  }
  
  i.right-align {
	background-image: url(./atoms/lexical/images/icons/text-right.svg);
  }
  
  i.justify-align {
	background-image: url(./atoms/lexical/images/icons/justify.svg);
  }
  
/* rich text lexical*/